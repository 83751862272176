import React from 'react'
import { Link } from 'gatsby'

import carouselOne from '/static/images/use-case/by-industry/media_feature_1.webp'
import carouselTwo from '/static/images/use-case/by-industry/media_feature_2.webp'
import carouselThree from '/static/images/use-case/by-industry/media_feature_3.webp'
import carouselFour from '/static/images/use-case/by-industry/media_feature_4.webp'

import { clickMessageBirdChat } from 'utils'

export const mediaFeatures = [
  {
    title: 'Reach global markets with one-click deployment',
    content: `For media and entertainment companies expanding internationally, managing content distribution across diverse geographical regions can be a logistical nightmare. Different regulations, varying network infrastructures, and localized content requirements often lead to fragmented delivery systems and inconsistent user experiences.<br /><br /> Mlytics platform offers a unified solution for global content distribution. With our intelligent geo-routing and multi-network orchestration, you can deploy your content worldwide with a single click, ensuring optimal performance and compliance in each region.`,
    imageSrc: carouselOne,
    imageAlt: 'Reach global markets with one-click deployment',
    hintContent: `Reach 214+ monitoring PoPs and 30,000+ Real User Monitoring (RUM) locations worldwide.`,
    children: (
      <button onClick={clickMessageBirdChat} className="bg-none h5-subtext-medium text-blue-100 underline my-3 w-fit">
        Explore global reach
      </button>
    )
  },
  {
    title: 'Industry-specific applications',
    content: `For media and entertainment providers, content delivery is not just about transmission. It's about user experience, customer retention, and brand reputation. The Mlytics platform manages streaming of 4K video content with millions of concurrent viewers for our customers daily.`,
    imageSrc: carouselTwo,
    imageAlt: 'Industry-specific applications',
    slot: `<ul class="ml-7 my-5 h5-subtext-medium text-black-70"><li class="list-disc">Live Event Streaming</li><li class="list-disc">Video-on-Demand (VOD)</li><li class="list-disc">Services Over-the-Top (OTT) Content Delivery</li></ul><p class="h5-subtext-medium text-black-70">Are you satisfied with your current content delivery performance? Let's discuss how we can elevate your streaming capabilities.</p>`,
    children: (
      <Link to="/contact-us" className="block w-full h-full py-2 text-blue-100 underline">
        Chat with us.
      </Link>
    )
  },
  {
    title: 'Revolutionize your streaming with adaptive technology',
    content: `For streaming providers, the real challenge begins when content goes live. Fluctuating viewer numbers, varying device capabilities, and unpredictable network conditions can significantly impact streaming quality and user experience. Outdated delivery systems, inadequate scalability, and inefficient content delivery platform can lead to buffering issues, quality degradation, and ultimately, subscriber churn. <br /><br /> Mlytics Platform not only integrates major networks like Akamai and AWS but also continuously develops cutting-edge technologies such as adaptive bitrate streaming, intelligent load balancing, and multi-CDN orchestration to effectively address these challenges.`,
    imageSrc: carouselThree,
    imageAlt: 'Revolutionize your streaming with adaptive technology',
    children: (
      <Link to="/contact-us" className="block w-full h-full py-2 text-blue-100 underline">
        Discover adaptive streaming
      </Link>
    )
  },
  {
    title: 'Optimize costs without compromising quality',
    content: `When dealing with large-scale video delivery, hidden costs in data transfer and storage can quickly accumulate. The Mlytics’ decisive engine enables you to implement sophisticated approaches such as using AI-driven traffic routing and intelligent caching strategies to minimize unnecessary data transfer while maintaining high availability and streaming quality.`,
    imageSrc: carouselFour,
    imageAlt: 'Optimize costs without compromising quality',
    hintContent: `Up to 30% cost reduction in video delivery expenses.`,
    children: (
      <Link to="/platform/decisive-engine" className="block h5-subtext-medium text-blue-100 underline my-3 w-fit">
        Learn about decisive engine.
      </Link>
    )
  }
]
